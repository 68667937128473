import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/LayoutTwo"
import { Helmet } from "react-helmet"

class Privacy extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Italian Art Trust O.N.L.U.S. - Privacy Policy</title>
          <meta
            name="description"
            content="This site gives an in-depth reading of the privacy policies associated to the Italian Art Trust O.N.L.U.S. as well as the treatment of personal data in line with current GDPR regulations of the European Union."
          />
        </Helmet>
        <Container>
          <section className="section" id="contatti">
            <div className="section__wrap">
              <h1 className="section__header cookie__header ">
                PRIVACY POLICY
              </h1>
              <p className="privacy__intro">
                Informativa ex art. 13 del Regolamento Privacy (UE) 679/2016 e
                del D.lgs. n. 196/2003, modificato dal D.lgs. n. 101/2018{" "}
              </p>
              <p className="privacy__text"> Gentile Supporter,</p>
              <p>
                desideriamo informarLa che il Regolamento Privacy (UE) 679/2016
                “Regolamento europeo in materia di protezione dei dati
                personali” (di seguito “GDPR”) prevede la tutela delle persone e
                di altri soggetti rispetto al trattamento dei dati personali.
              </p>
              <p className="privacy__text">
                Secondo la normativa indicata, tale trattamento sarà improntato
                ai principi di correttezza, liceità e trasparenza e di tutela
                della Sua riservatezza e dei Suoi diritti.{" "}
              </p>
              <p className="privacy__text">
                Ai sensi dell'articolo 13 del GDPR e del D.Lgs. n. 196/2003,
                modificato dal D.lgs. n. 101/2018, nonché in relazione ai dati
                personali dei quali L&P Trustee S.r.l. entrerà in possesso, Le
                forniamo le seguenti informazioni:
              </p>
              <h5 className="privacy__header">
                1) Finalità del trattamento dei dati{" "}
              </h5>
              <p className="privacy__text">
                a) Il trattamento verrà utilizzato per finalità connesse e/o
                strumentali alle attività e ai progetti del Trust denominato
                “Italian Art Trust Onlus”, c.f. 97873930156, e, comunque, per
                finalità connesse e/o strumentali allo svolgimento degli
                incarichi professionali affidati a L&P Trustee Srl - in qualità
                di Trustee di Italian Art Trust Onlus - escluso, pertanto, ogni
                utilizzo diverso e/o confliggente con i Suoi interessi.
              </p>
              <p className="privacy__text">
                b) Il trattamento dei dati avverrà anche al fine di rispettare
                gli obblighi incombenti sul professionista e previsti dalla
                normativa vigente, ivi compresi quelli in ambito fiscale e
                contabile nonché in materia antiriciclaggio ex D.Lgs. n.
                231/2007. .
              </p>
              <p className="privacy__text">
                c) Qualora il Titolare del trattamento intenda trattare
                ulteriormente i dati personali per una finalità diversa da
                quella per cui essi sono stati raccolti, prima di tale ulteriore
                trattamento, fornisce all’interessato informazioni in merito a
                tale diversa finalità e ogni ulteriore informazione pertinente
              </p>
              <h5 className="privacy__header">
                2) Base giuridica del trattamento
              </h5>
              <p className="privacy__text">
                Ai sensi dell’art. 6 del GDPR, il trattamento dei dati personali
                si fonda:<br></br>a) sulla necessità di adempiere ad un obbligo
                legale;<br></br>b) sul Suo consenso.
              </p>
              <h5 className="privacy__header">
                3) Modalità del trattamento dei dati{" "}
              </h5>
              <p className="privacy__text">
                a) Il trattamento dei Suoi dati personali avverrà, nel rispetto
                delle disposizioni previste dal GDPR, mediante strumenti
                cartacei, informatici e telematici, con logiche strettamente
                correlate alle finalità indicate e, comunque, con modalità
                idonee a garantirne la sicurezza e la riservatezza in conformità
                alle disposizioni previste dall’art. 32 del GDPR.
              </p>
              <p className="privacy__text">
                b) Il trattamento è svolto dal Titolare del trattamento, dai
                responsabili del trattamento e dagli incaricati al trattamento.
              </p>
              <p className="privacy__text">
                c) Il Titolare del trattamento e il responsabile mettono in atto
                misure tecniche e organizzative adeguate per garantire un
                livello di sicurezza adeguato al rischio.
              </p>
              <p className="privacy__text">
                d) Il trattamento dei dati di cui al punto 1) lett. a) e b) sarà
                effettuato sino al termine dell’incarico professionale (fermo
                restando l’obbligo di legge in relazione alla conservazione dei
                documenti).
              </p>
              <h5 className="privacy__header">4) Conferimento dei dati </h5>
              <p className="privacy__text">
                Il conferimento di dati personali comuni, sensibili e
                giudiziari, è strettamente necessario ai fini dello svolgimento
                delle attività di cui al punto 1).{" "}
              </p>
              <h5 className="privacy__header">
                5) Rifiuto di conferimento dei dati{" "}
              </h5>
              <p className="privacy__text">
                L’eventuale rifiuto da parte dell’interessato di conferire dati
                personali, comporta l’impossibilità di adempiere alle attività
                di cui al punto 1.{" "}
              </p>
              <h5 className="privacy__header">6) Comunicazione dei dati </h5>
              <p className="privacy__text">
                I dati personali possono essere comunicati per le finalità di
                cui al punto 1) a collaboratori esterni, soggetti operanti nel
                settore giudiziario, alle controparti e relativi difensori, ad
                enti pubblici, a consulenti o ad altri soggetti per
                l’adempimento degli obblighi di legge, a collegi di arbitri ed,
                in genere, a tutti quei soggetti cui la comunicazione sia
                necessaria per il corretto adempimento delle finalità indicate
                al punto 1).
              </p>
              <p className="privacy__text">
                Inoltre, i dati personali ed il relativo trattamento e le
                informazioni saranno oggetto di comunicazione, qualora ne
                ricorrano i presupposti, agli organi competenti in materia di
                normativa antiriciclaggio (Autorità e Organi di Vigilanza e
                Controllo).{" "}
              </p>
              <p className="privacy__text">
                Infine, i dati personali ed il relativo trattamento e le
                informazioni saranno oggetto di comunicazione, qualora ne
                ricorrano i presupposti, anche al gestore della mailing list.
              </p>
              <h5 className="privacy__header">7) Diffusione dei dati </h5>
              <p className="privacy__text">
                I dati personali non sono soggetti a diffusione.{" "}
              </p>
              <h5 className="privacy__header">
                8) Trasferimento dei dati all’estero{" "}
              </h5>
              <p className="privacy__text">
                Per le finalità di cui al punto 1), i dati potranno essere
                trasferiti al di fuori del territorio dell’Unione Europea, anche
                mediante l’inserimento degli stessi in database gestiti da
                società terze, operanti per conto di L&P Trustee S.r.l.. La
                gestione dei database ed il trattamento dei dati sono vincolati
                alle finalità per cui gli stessi sono stati raccolti ed
                avvengono nel massimo rispetto della legge applicabile sulla
                protezione dei dati personali. Infatti, il trasferimento ha
                luogo soltanto se il Titolare del trattamento e il responsabile
                del trattamento rispettano le condizioni indicate dal GDPR.
              </p>
              <p className="privacy__text">
                Nell’ambito delle finalità di cui al punto 1, il trasferimento
                dei dati deve essere eseguito secondo le disposizioni del GDPR
                ed al fine di assicurare che il livello di protezione delle
                persone fisiche garantito dal GDPR non sia pregiudicato.
              </p>
              <h5 className="privacy__header">9) Diritti dell’interessato </h5>
              <p className="privacy__text">
                Gli artt. dal 15 al 27 del GDPR conferiscono all’interessato
                l’esercizio di specifici diritti che potrà esercitare nei
                confronti del Titolare del trattamento.
              </p>
              <p className="privacy__text">
                In particolare, in relazione ai trattamenti descritti nella
                presente informativa, in qualità di interessato, Lei potrà, alle
                condizioni previste dal GDPR, esercitare i seguenti diritti:
              </p>
              <p className="privacy__text">
                • diritto di accesso: diritto di ottenere conferma che sia o
                meno in corso un trattamento di dati personali che La riguardano
                e, in tal caso, ottenere l'accesso ai Suoi dati personali –
                compresa una copia degli stessi – e la comunicazione, tra le
                altre, delle seguenti informazioni: <br></br>a) finalità del
                trattamento; <br></br>b) categorie di dati personali trattati;{" "}
                <br></br>c) destinatari cui questi sono stati o saranno
                comunicati; <br></br>d) periodo di conservazione dei dati o i
                criteri utilizzati; <br></br>e) diritti dell’interessato
                (rettifica, cancellazione dei dati personali, limitazione del
                trattamento e diritto di opposizione al trattamento); <br></br>
                f) diritto di proporre un reclamo; <br></br>g) diritto di
                ricevere informazioni sulla origine dei dati personali qualora
                essi non siano stati raccolti presso l’interessato; <br></br>h)
                l’esistenza di un processo decisionale automatizzato, compresa
                la profilazione;
              </p>
              <p className="privacy__text">
                • diritto di rettifica: diritto di ottenere la rettifica dei
                dati personali inesatti che La riguardano e/o l’integrazione dei
                dati personali incompleti;
              </p>
              <p className="privacy__text">
                • diritto alla cancellazione (diritto all’oblio): diritto di
                ottenere la cancellazione dei dati personali che La riguardano,
                quando:
                <br></br>a) i dati non sono più necessari rispetto alle finalità
                per cui sono stati raccolti o altrimenti trattati;
                <br></br>b) Lei ha revocato il Suo consenso e non sussiste alcun
                altro fondamento giuridico per il trattamento;
                <br></br>c) Lei si è opposto con successo al trattamento dei
                dati personali;
                <br></br>d) i dati sono stati trattati illecitamente;
                <br></br>e) i dati devono essere cancellati per adempiere un
                obbligo legale.
                <br></br>Il diritto alla cancellazione non si applica nella
                misura in cui il trattamento sia necessario per l’adempimento di
                un obbligo legale o per l’esecuzione di un compito svolto nel
                pubblico interesse o per l’accertamento, l’esercizio o la difesa
                di un diritto in sede giudiziaria;
              </p>
              <p className="privacy__text">
                • diritto di limitazione di trattamento: diritto di ottenere la
                limitazione del trattamento, quando:
                <br></br>a) l’interessato contesta l’esattezza dei dati
                personali;
                <br></br>b) il trattamento è illecito e l’interessato si oppone
                alla cancellazione dei dati personali e chiede invece che ne sia
                limitato l’utilizzo;
                <br></br>c) i dati personali sono necessari all’interessato per
                l’accertamento, l’esercizio o la difesa di un diritto in sede
                giudiziaria;
              </p>
              <p className="privacy__text">
                • diritto alla portabilità dei dati: diritto di ricevere, in un
                formato strutturato, di uso comune e leggibile da un dispositivo
                automatico, i dati personali che La riguardano forniti al
                Titolare del trattamento e il diritto di trasmetterli a un altro
                titolare senza impedimenti, qualora il trattamento si basi sul
                consenso e sia effettuato con mezzi automatizzati. Inoltre, il
                diritto di ottenere che i Suoi dati personali siano trasmessi
                direttamente dal Titolare del trattamento ad altro titolare
                qualora ciò sia tecnicamente fattibile;
              </p>
              <p className="privacy__text">
                • diritto di opposizione: diritto di opporsi, in qualsiasi
                momento, al trattamento qualora i dati personali siano trattati
                per finalità diverse da quelle per cui ha acconsentito al
                trattamento;
              </p>
              <p className="privacy__text">
                • proporre reclamo all’Autorità Garante per la protezione dei
                dati personali, Piazza di Montecitorio n. 121, 00186, Roma (RM).
              </p>
              <p className="privacy__text">
                Inoltre, il GDPR Le attribuisce il diritto di revocare il
                consenso prestato in qualsiasi momento e con la stessa facilità
                con cui è stato accordato.
              </p>
              <p className="privacy__text">
                I diritti di cui sopra potranno essere esercitati, nei confronti
                del Titolare del trattamento.
              </p>
              <p className="privacy__text">
                L’esercizio dei Suoi diritti in qualità di interessato è
                gratuito ai sensi dell’articolo 12 GDPR. Tuttavia, nel caso di
                richieste manifestamente infondate o eccessive, anche per la
                loro ripetitività, il Titolare del trattamento potrebbe
                addebitarle un contributo spese ragionevole, alla luce dei costi
                amministrativi sostenuti per gestire la Sua richiesta, o negare
                la soddisfazione della sua richiesta.
              </p>
              <h5 className="privacy__header">10) Titolare del trattamento </h5>
              <p className="privacy__text">
                Titolare del trattamento è L&P Trustee Srl in persona del suo
                amministratore unico con sede in Milano in via F.lli Gabba, 3 e
                con indirizzo email: amministrazione@leptrustee.it
              </p>
            </div>
          </section>
        </Container>
      </Layout>
    )
  }
}

export default Privacy
